
import { mapGetters } from "vuex"

export default {
	props: {
		item: {
			type: [Object, Number],
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
		categoryId: {
			type: Number,
			default: 0,
		},
		width: {
			type: [Number, String],
			default: "100%",
		},
		maxWidth: {
			type: [Number, String],
			default: "100%",
		},
	},

	data() {
		return {
			carouselModel: 0,
			isHovered: false,
			isWishlisting: false,
			isComparing: false,
		}
	},

	computed: {
		...mapGetters({
			isAllowedToAddToComparison: "comparison/isAllowedToAdd",
		}),
		varianceSlug() {
			if (this.isTypeBundle) {
				return this.item.bundle.slug
			} else {
				return this.item.variance?.slug
			}
		},
		stock() {
			if (this.isTypeBundle) {
				return this.item.bundle.stock
			}

			if (this.isTypeAlternative || this.isTypeSimple) {
				return this.item.variance?.stock
			}

			return false
		},

		isPreOrder() {
			return !!this.stock && this.stock.isPreOrder
		},
		hasStock() {
			return !!this.stock && this.stock.quantity > 0
		},

		hasVariance() {
			return !!this.item?.variance && this.item?.variance?.name
		},

		hasDiscount() {
			if (this.hasStock) {
				return this.stock.isOffer
			}

			return false
		},
		hasComparisonItems() {
			return !!this.$store.state.comparison.items.length
		},

		discount() {
			if (this.hasDiscount) {
				return 100 - Math.round((this.stock.price.value / this.stock.priceBeforeOffer.value) * 100)
			}
			return 0
		},
		discountAmount() {
			if (this.hasDiscount) {
				return {
					value: this.stock.priceBeforeOffer?.value - this.stock.price.value,
					currency: this.stock.price.currency,
				}
			}
			return 0
		},
		discountExpiresIn() {
			if (this.hasDiscount) {
				return this.stock.unPublishedAt
			}
			return 0
		},

		price() {
			if (this.hasStock) {
				return this.stock.price
			}
			return null
		},
		priceObject() {
			if (this.hasStock) {
				const symbol = this.stock.price.currency
				const formatter = new Intl.NumberFormat(this.$i18n.localeProperties.iso, {
					// style: "number",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})
				const value = formatter.format(this.stock.price.value)
				return { symbol, value }
			}

			return null
		},

		priceBeforeOffer() {
			return this.stock.priceBeforeOffer.value
		},

		isTypeSimple() {
			return this.item.type === "simple"
		},
		isTypeBundle() {
			return this.item.type === "bundle"
		},
		isTypeAlternative() {
			return this.item.type === "alternative"
		},
		computedLoading() {
			return this.loading
		},
		covers() {
			return [...(this.item.variance?.media?.cover || []), ...(this.item.media?.cover || [])]
		},
		cardProps() {
			if (!this.item.slug?.[this.$i18n.locale] || !this.item.variance?.slug?.[this.$i18n.locale]) { return {} }
			return {
				to: this.localePath({
					name: "product",
					params: {
						productSlug: this.item.slug?.[this.$i18n.locale],
						varianceSlug: this.item.variance?.slug?.[this.$i18n.locale],
					},
				}),
			}
		},
	},
	methods: {
		carouselStartHandler() {
			this.isHovered = true
			setTimeout(() => {
				if (this.isHovered) { this.carouselModel = 1 }
			}, 200)
		},
		carouselStopHandler() {
			this.isHovered = false
			setTimeout(() => {
				if (!this.isHovered) { this.carouselModel = 0 }
			}, 200)
		},
		addToWishlist() {
			this.isWishlisting = true
			this.$store.dispatch("wishlist/add", this.item).finally(() => {
				this.isWishlisting = false
			})
		},
		addToComparison() {
			// let isConfirmed = true;
			// if (!this.isAllowedToAddToComparison(this.categoryId)) {
			// 	isConfirmed = false;
			// 	isConfirmed = await this.$confirm(
			// 		"You can compare products from the same category. Do you want to clear the list and add this item?",
			// 		{
			// 			// confirmButtonText: "Remove",
			// 			// cancelButtonText: "Cancel",
			// 			title: "Comparison can't be done!",
			// 		}
			// 	).then((isAccepted) => {
			// 		if (isAccepted) {
			// 			this.$store.dispatch("comparison/removeAll");
			// 		}

			// 		return isAccepted;
			// 	});
			// }
			// if (isConfirmed) {
			this.isComparing = true
			this.$store
				.dispatch("comparison/add", {
					productSlug: this.item.slug[this.$i18n.locale],
					varianceSlug: this.varianceSlug[this.$i18n.locale],
				})
				.finally(() => {
					this.isComparing = false
				})
			// }
		},
		removeFromWishlist() {
			this.isWishlisting = true
			const id = this.item.productId
			this.$store.dispatch("wishlist/remove", this.item).finally(() => {
				this.isWishlisting = false
				this.$emit("wishlist:remove", id)
			})
		},
	},
}
